
.theory-libaray-content {
    box-sizing: border-box;
    & * {
        box-sizing: border-box;
    }
    width: 100%;
    height: 100%;
    padding: 30px;
    .header-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .input-text {
        width: 70%;
    }
    .el-btn-group {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .deep-blue {
        &::v-deep.el-button--text {
            color: #1122D8;
            font-size: 14px;
        }
    }
    .light-red {
        &::v-deep.el-button--text {
            color: #EB6547;
            font-size: 14px;
        }
    }
    .data-list {
        width: 100%;
        height: calc(100% - 85px);
        &::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        &::v-deep .el-table {
            &.libaray-table {
                margin-top: 10px;
            }
            .table-header-deep {
                background-color: rgb(242, 243, 250);
            }
        }
    }
    .data-page {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 35px;
    }
}
.add-dialog {
    .father-libaray {
        font-weight: 600;
        color: #999999;
    }
    &::v-deep .el-dialog {
        .el-dialog__header {
            background-color: #1122D8;
            color: #FFF;
            .el-dialog__title {
                color: #FFF;
            }
            .el-dialog__headerbtn {
                .el-dialog__close {
                    color: #FFF;
                }
            }
        }
    }
}


